import request from '@/utils/request'
import {UserInfoDto, CreateOrUpdateUserInfoDto} from '@/dto/UserInfoDto'

const url = '/keep-fit/memberShip'

export function getCurrentUserAsync(openid: string | null): Promise<UserInfoDto> {
  return new Promise<UserInfoDto>((resolve, reject) => {
    request({
      url: `${url}/currentUser`,
      method: 'get',
      params: {
        openid
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function saveUserInfoAsync(userInfo: CreateOrUpdateUserInfoDto) {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}/save`,
      method: 'post',
      data: userInfo
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}
