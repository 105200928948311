




































import {Component, Vue} from 'vue-property-decorator';
import {UserInfoDto, CreateOrUpdateUserInfoDto} from '@/dto/UserInfoDto';
import {getCurrentUserAsync, saveUserInfoAsync} from '@/api/memberShip'

import {areaList} from '@vant/area-data';

@Component({})
export default class UserInfo extends Vue {

  private userInfo: CreateOrUpdateUserInfoDto = {
    address: '',
    district: '',
    email: '',
    phone: '',
    realName: '',
    sex: '',
    remark: '',
    faceUrl: ''
  };
  private showArea = false;
  private areaList = areaList;
  // 手机号正则表达式
  private pattern = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/

  private async activated() {
    await this.getUserInfo()
  }

  private async getUserInfo() {
    const openid = localStorage.getItem('Keep_OpenId')
    const result = await getCurrentUserAsync(openid);
    if (result) {
      this.userInfo = Object.assign({}, result)
    }
  }

  private async onSubmit(values: any) {
    console.log(this.userInfo)
    await saveUserInfoAsync(this.userInfo);
    this.$toast({message: '个人信息保存成功', type: 'success'})
  }

  private onConfirm(values: any) {
    this.userInfo.district = values
      .filter((item: any) => !!item)
      .map((item: any) => item.name)
      .join('/');
    this.showArea = false;
  }
};
